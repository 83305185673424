<script setup>
import {useRouter} from "vue-router";

const router = useRouter();
const navigatorTo = function (url) {
  router.push(url);
};
</script>

<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/00.png" alt="" style="height: 40px;">
    </div>
  </div>
  <div class="nav">
    <span @click="navigatorTo('/home')" class="nav_item">Home</span>
    <span class="nav_item_dashed"></span>
    <span @click="navigatorTo('/about')" class="nav_item">About us</span>
    <span class="nav_item_dashed"></span>
    <span @click="navigatorTo('/products')" class="nav_item">Products</span>
    <span class="nav_item_dashed"></span>
    <span @click="navigatorTo('/contacts')" class="nav_item">Contact Us</span>
    <span class="nav_item_dashed"></span>
    <span @click="navigatorTo('/message')" class="nav_item">Message</span>
    <span class="nav_item_dashed"></span>
  </div>
</template>

<style scoped lang="scss">
.header {
  width: 959px;
  height: 102px;
  overflow: hidden;
  background-image: url("@/assets/top.jpg");
  background-repeat: repeat-x;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
  padding-top: 30px;
  overflow: hidden;
  margin-bottom: 5px;
  margin-left: 20px;
}

.nav {
  padding-left: .6rem;
  box-sizing: border-box;
  width: 959px;
  height: 45px;
  line-height: 45px;
  margin: 0 auto;
  background-image: url("@/assets/nav1_06.gif");
  background-repeat: repeat-x;
  display: flex;
  align-items: center;
  user-select: none;
  color: #fff;

  .nav_item {
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    width: 90px;
    transition: all .3s;

    &:hover {
      background: linear-gradient(to top, #3169a6, #78c5ee)
    }
  }

  .nav_item_dashed {
    border-right: 1px dashed #ffffff;
    height: 40%;
    margin: 0 .2rem;
  }
}
</style>
