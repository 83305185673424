<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue' // swiper 所需组件
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {A11y, Autoplay, Navigation} from "swiper/modules";

const modules = [Autoplay, Navigation, A11y]
</script>

<template>
  <swiper
      :slidesPerView="1"
      :spaceBetween="30"
      :loop="true"
      :centeredSlides="true"
      :autoplay="{ delay: 2500, disableOnInteraction: false}"
      :navigation="false"
      :modules="modules"
  >
    <swiper-slide><img src="@/assets/swiper.jpeg" style="width: 100%;height: 100%;" alt=""/></swiper-slide>
    <swiper-slide><img src="@/assets/swiper1.jpeg" style="width: 100%;height: 100%;" alt=""/></swiper-slide>
  </swiper>
</template>

<style scoped lang="scss">

</style>
