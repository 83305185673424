import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path:'/',
    redirect:"/home"
  },
  {
    path: '/home',
    name: 'home',
    component: ()=> import("@/views/Home")
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs')
  },{
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products')
  },{
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactsUs')
  },{
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "about" */ '../views/Message')
  },{
    path: '/product_desc',
    name: 'product_desc',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductDesc')
  },{
    path: '/rightNav',
    name: 'rightNav',
    component: () => import(/* webpackChunkName: "about" */ '../views/RightNav')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
